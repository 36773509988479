@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');

.header-review { 
    padding: 20px 10px;
    background-color: rgb(245, 245, 245);
}

.review-header-img {  
    height: 50px;
}

.review-form {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin: auto;
    padding: 0 20px ;
}

textarea {
    resize: none;
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
}

.review-header { 
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
}

.reviewsubmit-btn {
    margin-top: 20px;
    background-color: #4a79dd;
    color: #FFFF;
}

.reviewstar-imgdiv {
    display: flex;
    justify-content: center;
}