@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap');

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  padding-left: 10px;
}

/* loading spinner */

.svg-spinner {
  /* align-items: center; */
  width: 3.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
 }
 
 circle {
  fill: none;
  stroke: hsl(214, 97%, 59%);
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
 }
 
 @keyframes rotate4 {
  100% {
   transform: rotate(360deg);
  }
 }
 
 @keyframes dash4 {
  0% {
   stroke-dasharray: 1, 200;
   stroke-dashoffset: 0;
  }
 
  50% {
   stroke-dasharray: 90, 200;
   stroke-dashoffset: -35px;
  }
 
  100% {
   stroke-dashoffset: -125px;
  }
 }

 /* end */

/* Overlay */

.overlay {
  position: relative;
}

.overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the color and opacity as desired */
  z-index: 1; /* Adjust the z-index to ensure the overlay appears above other elements */
}