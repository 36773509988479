
.main-div {
  width: 80%;
  max-width: 600px;
  margin: auto;
}

.topic-instructions p {
  color: #6F6F6F;
}

.section {
  margin-top: 50px;
}

.main-div form input[type="text"] {
  margin-top: 15px;
  margin-bottom: 25px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding-left: 30px;
  font-weight: 400;
  cursor: auto;
}

.main-div form input[type="email"] {
  margin-top: 15px;
  margin-bottom: 25px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding-left: 30px;
  font-weight: 400;
  cursor: auto;
}

.main-div form input[type="date"] {
  margin-top: 15px;
  margin-bottom: 25px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding-left: 30px;
  font-weight: 400;
  cursor: auto;
}

.main-div form input[type="checkbox"] {
  margin: 10px;
}

.radio-btn-div input {
  margin-top: 20px;

}



.form button {
  all: unset;
  padding: 15px;
  margin-top: 20px;
  border-radius: 5px;
  background-color: #4285F4;
  color: white;
}